dd<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招生管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">招生列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ stu != "edit" ? "新增" : "修改" }}招生院校</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="7.5rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="招生院校：" prop="compName">
                  <el-input v-model="ruleForm.compName" size="small"></el-input>
                </el-form-item>

                <el-form-item
                  label="招生详情："
                  prop="admissionsContext"
                  class="form-item"
                >
                  <div
                    ref="editor"
                    class="editor"
                    style="width: 100%; max-width: 1200px"
                  />
                </el-form-item>
                  <el-form-item label="院校地址：" prop="areaId">
                    <el-input
                      placeholder="请输入详细地址"
                      v-model="ruleForm.address"
                      size="small"
                      class="input-with-select"
                    >
                      <el-cascader
                      class="casStyle"
                       slot="prepend"
                        :options="areaTreeList"
                        clearable
                        filterable
                        :props="propsArea"
                        size="small"
                        v-model="ruleForm.areaId"
                      ></el-cascader>
                    </el-input>
                  </el-form-item>
              </el-form>
            </div>
            <div class="btn-box">
              <el-button @click="doHandleOk" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                >保存
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <MenuStyle ref="MenuStyle" @eventBus="styleBack" /> -->
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
export default {
  name: "operate/accountEdit",
  components: {},
  data() {
    return {
      stu: "add",
      // 基本信息
      ruleForm: {
        compName: "", //招生院校
        admissionsContext: "", // 招生详情
        areaId:'', //行政区划
        address:'', //详细地址
        admissionsId:'', //招生Id
      },
      rules: {
        compName: [
          { required: true, message: "请输入招生院校", trigger: "blur" },
        ],
        admissionsContext: [
          { required: true, message: "请输入招生详情", trigger: "change" },
        ],
       
      },
      areaTreeList: [], //行政区划
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    this.getAreaTree();
    if (this.stu == "edit") {
      this.ruleForm.admissionsId = this.$route.query.admissionsId;
      this.getDetail(this.ruleForm.admissionsId);
    }
  },
  mounted() {
    this.editorInit();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
  },
  computed: {},
  methods: {
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
        //   editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      // editor.config.pasteFilterStyle = "text";
      editor.config.pasteFilterStyle = false;
      editor.config.pasteIgnoreImg = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };

      editor.config.onchange = (html) => {
        this.ruleForm.admissionsContext = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },

    /**
     * 新增
     */
    doAddSave(formName) {
      const params = {
        compName: this.ruleForm.compName,
        admissionsContext: this.ruleForm.admissionsContext,
      };
      if (this.ruleForm.areaId) {
          params.areaId = this.ruleForm.areaId
      }
      if (this.ruleForm.address) {
          params.address = this.ruleForm.address
      }
      if (this.stu == "edit") {
        params.admissionsId = this.ruleForm.admissionsId;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.stu != "edit"
              ? "/admissions/insert"
              : "/admissions/modify",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == "0") {
                if (this.stu != "edit") {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                }
                this.doHandleOk();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /**
     * 编辑回显
     */
    getDetail(admissionsId) {
      this.$post(
        "/admissions/getInfo",
        { admissionsId },
        3000,
        true,
        2
      ).then((ret) => {
        this.ruleForm = {
          ...ret.data,
        };
        editor.txt.html(ret.data.admissionsContext);
      });
    },
    /**
     *  行政区划
     */
    getAreaTree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areaTreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    /**
     * 返回
     */
    doHandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/miniApp/enrollmentManagementList",
          query: {
            refresh: true,
          },
        });
      }, 300);
    },
  },
};
</script>
<style lang="less" scoped>
 /deep/.el-input-group__prepend {
    padding: 0 20px 0 0;
    border:none !important;

}
.btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.casStyle {
    width: 300px;
}
</style>
